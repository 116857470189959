@import "./font/style.css";
@import url("https://fonts.googleapis.com/css2?family=Krona+One&family=Kumbh+Sans:wght,YOPQ@100..900,40..300&display=swap");

body {
  font-family: "Krona One", sans-serif !important;
  background-color: rgba(17, 18, 22, 1) !important;
  color: #fff !important;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  margin: 0 0 0.8rem 0;
}

textarea {
  display: block;
  width: 100%;
  margin: 0 0 0.8rem 0;
  padding: 0.5rem;
}

img.mobWorkAroundImg {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.scrollDownArrow {
  width: 130px;
  position: absolute;
  bottom: 100px;
}

video::-webkit-media-controls {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

.extraFontsCont p,
.extraFontsCont li,
.extraFontsCont li span {
  font-family: "Kumbh Sans", sans-serif !important;
}

.footerCont a svg {
  stroke: #fff;
}

.nopartyFallBack {
  text-align: center;
}

.nopartyFallBack h3 {
  font-size: 20px;
  text-transform: uppercase;
}

.nopartyFallBack p {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
}

.nopartyFallBack img {
  width: 120px;
  margin-bottom: 2rem;
}

.nopartyFallBack {
  max-width: 700px;
  margin: 0 auto;
}

input:focus {
  border: 1px solid #fff !important;
  /* New border color on focus */
  box-shadow: none !important;
  /* Shadow effect */
  outline: none !important;
  /* Remove default outline */
  background: transparent !important;
}

h2.partiesHostedTit {
  font-size: 24px;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.lefInput input:focus {
  border: 1px solid transparent !important;
  border-right: 1px solid #3f3f46 !important;
}

.promoInputCont input:focus {
  border: none !important;
  outline: none !important;
}

ol.roman li {
  margin-bottom: 5px;
}

ol.roman li::marker {
  min-width: 15px;
}

.cannotMore {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
  text-align: center;
}

.hostPartyModalForm input:focus {
  color: #fff !important;
}

div#root {
  width: 100vw;
  // overflow-x: hidden;
}

$theme-colors: (
  "primary": #ff1844,
  "secondary": #4bb4b2,
  "light": #f7f7f7,
  "dark": #353535,
  "medium": #4a4a4a,
);

$border-color: #979797;

$navbar-light-color: rgba(#000000, 0.9) !default;
$navbar-light-hover-color: rgba(#000000, 0.8) !default;
$navbar-light-active-color: #ff1844 !default;
//$navbar-light-active-color: #ff1844;

$font-family-sans-serif: "Airbnb Cereal App Book";
$enable-responsive-font-sizes: "true";

$font-size-base: 0.875rem !default; //14px
$small-font-size: 85% !default; //12px

$h1-font-size: $font-size-base * 2.5 !default; //35px
$h2-font-size: $font-size-base * 2 !default; //28px
$h3-font-size: $font-size-base * 1.71 !default; //24px
$h4-font-size: $font-size-base * 1.42 !default; //20px
$h5-font-size: $font-size-base * 1.28 !default; //18px
$h6-font-size: $font-size-base !default; //14px

$headings-font-family: "Krona One" !default;

.btn {
  &:focus,
  &.focus {
    outline: 0 !important;
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

.group {
  position: relative;

  input {
    padding: 8px 10px 8px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }

  input:focus {
    outline: none;
    border: 1px solid #757575;
  }

  /* LABEL ======================================= */
  label {
    color: #999;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  input:focus ~ label,
  input:valid ~ label {
    top: -14px;
    font-size: 12px;
    color: #ff1844;
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #ff1844;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 0%;
  }

  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 100%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #ff1844;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @-moz-keyframes inputHighlighter {
    from {
      background: #ff1844;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @keyframes inputHighlighter {
    from {
      background: #1844;
    }

    to {
      width: 0;
      background: transparent;
    }
  }
}

.profileClicked a {
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  font-family: "Kumbh Sans", sans-serif !important;
}

.profileClicked a:hover {
  color: #fff !important;
}

.profileClicked {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 60px;
  border: 1px solid #f8f8f833;
  border-radius: 12px;
  color: #fff;
  background: #111215;
  font-family: "Kumbh Sans", sans-serif;
  padding: 10px 15px;
}

.sticky-ticket {
  position: sticky;
  top: 6rem;
}

.parties-heart {
  position: absolute;
  top: 3%;
  left: 85%;
}

.spaces-heart {
  position: absolute;
  top: 75%;
  left: 85%;
}

.fw-bolder {
  font-family: "Airbnb Cereal App Extra Bold" !important;
}

.fw-bold {
  font-family: "Airbnb Cereal App Bold" !important;
}

.fw-medium {
  font-family: "Airbnb Cereal App Medium" !important;
}

.mTop {
  margin-top: 7rem;
}

.bg-gray {
  background: #f9f9f9;
}

.selected {
  color: #ff1844 !important;
}

.filter-btn {
  border: 1px solid #ddd !important;
  background-color: transparent;
}

.selected-filter {
  background-color: #ff1844 !important;
  color: white !important;
  border: none !important;
}

.border-round-left {
  border-radius: none !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.security-section {
  position: relative;
}

.attend-no-wrap {
  flex-wrap: nowrap !important;
}

.make-pointer {
  cursor: pointer !important;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
}

.onfido-sdk-ui-Modal-overlay {
  top: 40px;
}

.ahe-img {
  height: auto;
  width: 25%;
}

//SPACES ON HOME
.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 1.7rem;
}

.grid-col {
  position: relative;
  display: flex;
  flex-direction: column;

  a {
    display: block;
    height: 100%;
  }
}

/////////////////////////////////////////////////
//PARTIES AND SPACES PAGE GRID
.grid-container {
  display: grid;
  grid-gap: 20px;
  // grid-template-columns: auto auto auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  min-height: 0;
  min-width: 0;
}

.grid-item {
  position: relative;
}

////////////////////////////////////////////////
.filters-container {
  float: right;
  display: flex;
}

.photo-carousel {
  background: #ffffff;

  .carousel-inner {
    // width: 63%;
    // margin: 0 auto;
    // height: 394px;

    .carousel-item {
      display: flex;
      // justify-content: flex-end;
      // align-items: center;
      margin: 0 auto;
      height: 394px;
    }
  }
}

.ticket {
  height: 80px;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.175);
}

.page-link {
  &:focus {
    box-shadow: none !important;
  }
}

.img-wrapper {
  height: 200px;
  background: #f7f7f7;
  border-top-left-radius: "8px";
  border-top-right-radius: "8px";
}

button:disabled {
  cursor: not-allowed;
}

//SHIMMER ******************************
.animate {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 500px 100%;
}

.darkBG {
  background: rgba(17, 18, 22, 1);
}

.customInput {
  border: 1px solid rgba(61, 62, 68, 1) !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px !important;
}

.subscribeText {
  font-size: 14px;
  line-height: 22.4px;
  text-transform: uppercase;
  font-family: "Krona One";
  margin-bottom: 24px !important;
}

.wholegbg_black {
  background-color: rgba(17, 18, 22, 1);
  color: #fff;
}

.subsBtn {
  color: #ff4571 !important;
  background-color: transparent !important;
  border: none !important;
  outline: none;
  font-size: 14px !important;
  line-height: 20px !important;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  pointer-events: all;
}

.kumbh {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
  line-height: 22.4px;
  padding-top: 5rem;
  padding-bottom: 2rem !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto !important;
}

.kumbh svg {
  width: 20px;
}

.subsBtn:hover {
  background-color: transparent !important;
}

.homefaqgrid {
  display: flex;
  // margin: 0px 60px;
  padding: 75px 0;
  gap: 60px;
  flex-wrap: wrap;
}

.topText p {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0;
}

.flexBtn_cont p {
  font-size: 18px;
  line-height: 21.78px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.outlineBtn {
  font-family: "Kumbh Sans", sans-serif;
  background: transparent;
  color: #fff;
  outline: none;
  border: 2px solid #3f3f46;
  border-radius: 12px;
  padding: 10px 15px;
}

.leftSide {
  min-width: 450px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.rightSide_faq {
  flex: 1;
}

.flexBtn_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middleBanner {
  background-color: rgb(51, 55, 57);
  color: #fff;
  padding: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.discoBall {
  position: absolute;
  left: -26px;
  width: 75px;
  top: -35px;
}

.padAd {
  padding: 80px 0;
}

.middleBanner p {
  margin-bottom: 0;
  text-transform: uppercase;
}

.videosCont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}

.experienceVideos_cont {
  padding: 80px 0;
}

.videoEx {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.nameNall {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(19, 20, 23, 0) 7.99%,
    #1f2223 68.03%
  );
  bottom: 0;
  width: 100%;
  padding: 15px 20px;
}

.titleMid {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 5rem;
}

.nameNall p:nth-child(1) {
  font-size: 24px;
  line-height: 29.77px;
  margin-bottom: 5px;
}

.nameNall p {
  font-size: 18px;
  line-height: 22.32px;
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
}

.videoEx video {
  width: 100%;
}

.oddText {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  color: rgba(51, 55, 57, 1);
  position: relative;
}

.oddText p {
  font-size: 25px;
  margin-bottom: 0;
}

.oddText p span {
  color: #fff;
}

.smolInfo_cont {
  margin: 10rem 0;
}

.infoWine {
  position: absolute;
  bottom: -70px;
  right: 0px;
}

.infoDisco {
  position: absolute;
  left: -65px;
  top: -45px;
  width: 80px;
}

.bgVideoLoop_stack .swiper-slide {
  text-align: center;
}

.bgVideoLoop_stack .swiper-container {
  background: linear-gradient(
    180deg,
    rgba(19, 20, 23, 0) 7.99%,
    #121417 68.03%
  );
}

h4.make-pointer {
  font-family: "Kumbh Sans";
  margin: 0 10px;
}

h2.titleStack {
  text-align: center;
  background: linear-gradient(0deg, rgba(19, 20, 23, 0) 7.99%, #121417 68.03%);
  font-size: 40px;
  line-height: 40.8px;
  z-index: 999;
  margin-bottom: 1.5rem;
}

.slick-slider {
  background: linear-gradient(
    179deg,
    rgba(19, 20, 23, 0) 7.99%,
    #121417 68.03%
  );
}

.bgVideoLoop_stack {
  min-height: 78vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  // background: url(https://cdn.pixabay.com/photo/2017/07/21/23/57/concert-2527495_1280.jpg);
  position: relative;
}

.chaningImages {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.chaningImages img {
  object-fit: cover;
}

.logoContBrands {
  min-height: 120px;
  display: flex !important;
  justify-content: center;
}

section.attend-section {
  background: rgba(17, 18, 22, 1);
  color: #fff;
}

.locationNdate p {
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}

.locationNdate svg {
  width: 20px;
}

.locationNdate {
  font-family: "Kumbh Sans", sans-serif;
  gap: 20px;
}

.eventPrice h4 {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
}

.locationCont.flexCenter {
  gap: 8px;
  text-transform: capitalize;
}

.dateCont.flexCenter {
  gap: 8px;
}

.gridParty {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 2rem;
}

// .bgVideoLoop_stack img {
//   max-width: 200px;
//   width: 200px;
//   max-height: 72px;
//   object-fit: cover;
// }

.partiesHead {
  font-size: 24px;
  line-height: 26.4px;
}

.gridParty .img-wrapper {
  height: 300px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.hostedByDiv {
  color: #505050;
  font-size: 16px;
  line-height: 19.84px;
  font-family: "Kumbh Sans", sans-serif;
}

.flexColl {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.eventTit h3 {
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 0;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.thirdBtn {
  background-color: rgba(97, 19, 35, 1) !important;
  border: 1px solid rgba(255, 24, 68, 1) !important;
  color: #fff !important;
}

.profile_cont button a {
  color: #fff;
}

.darkgrayBtn {
  border: 2px solid rgba(63, 63, 70, 1) !important;
  background-color: rgba(39, 39, 42, 1) !important;
  border-radius: 12px !important;
}

.darkgrayBtn a svg {
  stroke: rgba(255, 69, 113, 1);
}

.profile_cont {
  display: flex;
  gap: 30px;
  position: relative;
}

.heroText h1 {
  text-transform: uppercase;
  font-size: 45px;
}

.heroText button {
  box-shadow: 0px 10px 15px -3px rgba(255, 24, 68, 0.4);
  width: 200px;
  padding: 10px;
}

.heroText button svg {
  width: 20px;
}

.heroText button a {
  color: #fff;
}

.outlineGray {
  border: 2px solid rgb(63, 63, 70) !important;
  color: #fff !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-family: "Kumbh Sans", sans-serif;
  padding: 8px 15px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.brandsCar_cont {
  margin-top: 5em;
}

.outlineGray svg {
  width: 20px;
}

.heroText {
  max-width: 760px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.mainHero_cont video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  user-select: none;
}

.mainHero_cont {
  position: relative;
  height: 100vh;
  display: flex;
  z-index: 1;
  align-items: center;
  overflow: hidden;
}

.customizeBtn {
  font-family: "Kumbh Sans", sans-serif;
  border-radius: 12px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.bottonGradient {
  background: linear-gradient(
    157.78deg,
    rgba(211, 56, 236, 0.25) 5.84%,
    rgba(131, 56, 236, 0.25) 56.46%,
    rgba(58, 134, 255, 0.25) 107.07%
  );
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: 0;
  right: -76px;
  z-index: 2;
  filter: blur(45px);
  // transform: rotate(-12.87deg);
  animation: djparty 5s linear infinite;
}

.topGradient {
  background: linear-gradient(
    157.78deg,
    rgba(131, 56, 236, 0.5) 5.84%,
    rgba(58, 134, 255, 0.5) 107.07%
  );
  width: 300px;
  height: 300px;
  position: absolute;
  top: -50px;
  left: -76px;
  z-index: 2;
  filter: blur(45px);
  // transform: rotate(-12.87deg);
  animation: djparty 5s linear infinite;
}

@keyframes djparty {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
}

.profile_cont button a {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none !important;
}

.customizeBtn:hover {
  box-shadow: 0px 10px 15px -3px rgba(255, 24, 68, 0.4);
}

.iconSpace {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.iconSpace:hover {
  color: #fff !important;
}

.profile_cont svg {
  width: 20px;
}

.eventDetGap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.verifyGrid {
  display: flex;
  align-items: center;
  gap: 90px;
}

.singleInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
  font-family: "Kumbh Sans", sans-serif;
}

.singleInfo h4 {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 24px;
}

.verifiedAnimate {
  padding: 5rem 0;
}

.infoText h4 {
  color: #333739;
  transition: color 1s ease-in-out;
}

.infoText p {
  transition: color 1s ease-in-out;
  font-size: 16px;
  line-height: 19.84px;
  color: #333739;
  margin-bottom: 0;
}

.circleIndicator {
  width: 45px;
  height: 45px;
  border: 2px solid rgba(61, 57, 57, 1);
  border-radius: 50%;
}

.badgeIcon {
  background: #872fff;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  padding: 5px 10px;
  width: fit-content;
}

.badgeIcon h4 {
  font-size: 16px;
  line-height: 19.84px;
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
  text-transform: capitalize;
}

.badgeCont {
  /* margin-bottom: 10px; */
  position: absolute;
  top: 9px;
  left: 10px;
}

div#sticky-ticket {
  background: #33373959;
  border: 1px solid #f8f8f84d;
  border-radius: 24px;
}

.guestCountOp {
  background: #333739;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-bottom: 2rem;
}

.guestCountOp p {
  font-size: 16px;
  line-height: 22.4px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.unitEconomicsCont p {
  font-size: 16px;
  line-height: 22.4px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.countCont {
  display: flex;
  gap: 13px;
  align-items: center;
}

.guestCountOp button {
  background: #686866;
  border: none;
  outline: none;
  border-radius: 12px;
  width: 36px;
  height: 36px;
  color: #fff;
  position: relative;
}

.memReachToolTip span {
  font-size: 12px;
  display: block;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 500;
}

.memReachToolTip {
  position: absolute;
  top: -45px;
  right: -35px;
  width: 160px;
  background: #ff1844;
  color: #fff !important;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: 0.15s;
}

.memReachToolTip::after {
  content: "";
  position: absolute;
  bottom: -15px;
  right: 38px;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #ff1844 transparent transparent transparent;
}

.toolTipReach.show:hover .memReachToolTip {
  opacity: 1;
  top: -53px;
}

.unitEconomicsCont {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.w-full {
  width: 100%;
}

.finaltotal .rightUnit p {
  font-size: 24px;
  line-height: 33.6px;
}

.finaltotal p {
  margin-bottom: 0;
}

.finaltotal {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.topEvPrice {
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
  gap: 10px;
}

.topEvPrice p {
  margin-bottom: 0;
  line-height: 14.88px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 700;
}

.topEvPrice h5 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 0;
}

.whatsappFlow input {
  border: 2px solid #3f3f46;
  background: transparent;
  border-radius: 12px;
  font-family: "Kumbh Sans", sans-serif;
  color: #fff;
  padding-left: 2.5rem;
}

.newFlowRevam input,
.newFlowRevam input {
  border: 2px solid #3f3f46;
  background: transparent;
  border-radius: 12px;
  font-family: "Kumbh Sans", sans-serif;
  color: #fff;
}

.inputContsFill select {
  border: 2px solid #3f3f46;
  background: #27282c;
  border-radius: 12px;
  font-family: "Kumbh Sans", sans-serif;
  color: #fff;
  width: 100%;
  padding: 8px;
}

.guestListInputs {
  background-color: #27282c;
  border-radius: 24px;
  padding: 24px;
  margin-top: 2.5rem;
}

.guestsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whatsappFlow p {
  font-size: 12px;
  line-height: 16.8px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 1.8rem;
}

.newFlowRevam {
  margin-top: 4rem;
}

.newFlowRevam h3 {
  margin-bottom: 2rem;
}

.whatsappOtpMod .modal-content {
  background: #27282c;
}

.succesorder .modal-content {
  background-color: #27282c;
  padding: 20px;
  min-width: 600px;
  text-align: center;
}

.whatsappOtpMod .modal-header .modal-title {
  font-size: 24px;
  line-height: 26.4px;
  font-family: "Kumbh Sans", sans-serif;
}

.messageNum p {
  font-size: 18px;
  line-height: 28.8px;
  font-family: "Kumbh Sans", sans-serif;
}

.otpInputs {
  border: 2px solid #3f3f46 !important;
  background-color: transparent !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0509803922);
  height: 47px !important;
  color: #fff !important;
  border-radius: 12px !important;
}

.whatsappNumForm button {
  font-family: "Kumbh Sans";
  line-height: 24px;
  font-size: 16px;
}

.showVerified {
  display: flex;
  align-items: center;
  background: #27272a;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 12px;
  padding: 10px 12px;
  gap: 10px;
}

.whatsNum p {
  margin: 0;
}

.showVerified p {
  color: #828282;
}

.eventDetailsSuccess {
  background: #333739;
  padding: 20px;
  border-radius: 12px;
  margin-top: 2rem;
}

.smolCardImg {
  background: #d9d9d9;
  width: 190px;
  height: 92px;
  border-radius: 8px;
}

.smolCardImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

button.whiteFull a {
  color: inherit;
}

button.whiteFull {
  border: none;
  outline: none;
  width: 100%;
  color: #1f2024;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  border-radius: 12px;
  font-family: "Kumbh Sans";
  font-weight: 500;
  margin-top: 1rem;
}

.rightEventDetails h1 {
  line-height: 26px;
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;
}

.gap15 {
  gap: 15px;
}

.suctit {
  line-height: 26.4px;
  font-size: 23px;
  text-align: center;
  margin-top: 3rem;
}

.verySmallP {
  font-size: 12px;
  line-height: 16.8px;
  font-family: "Kumbh Sans";
  margin-bottom: 0;
  margin-top: 1rem;
  text-align: center;
}

.cancelCommon h6 {
  font-size: 24px;
  line-height: 26.4px;
}

.cancelCommon p {
  font-size: 14px;
  line-height: 23.6px;
  font-family: "Kumbh Sans";
  margin-bottom: 0;
}

.cancelCommon textarea {
  background-color: transparent;
  border: 1px solid #3f3f46;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Kumbh Sans";
  color: #fff !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileVerificationCont {
  background: #47c97e1a;
  padding: 20px;
  border-radius: 12px;
  margin-top: 16px;
}

.profileVerificationCont h4 {
  font-size: 16px;
  line-height: 25.6px;
  text-transform: uppercase;
}

.profileVerificationCont button {
  border: none;
  outline: none;
  width: 100%;
  color: #1f2024;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  border-radius: 12px;
  font-family: "Kumbh Sans";
  font-weight: 500;
}

.absoluteImg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
}

.wholeCardDetails {
  border: 1px solid #f8f8f84d;
  background-color: #33373959;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.covertimeline {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 40px;
}

h2.titleParty {
  line-height: 32px;
  font-size: 20px;
  text-transform: uppercase;
}

p.hostedBy {
  font-size: 16px;
  line-height: 19.84px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.twoBtnsCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partyTimelineDetailsCont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 25px 1rem 0;
  justify-content: center;
}

.partyDateCont {
  padding-left: 70px;
  position: relative;
}

.partydateHead {
  position: relative;
  margin-bottom: 2rem;
}

.timeLineDottedCont {
  position: relative;
}

.timeLineDottedCont::after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 1px;
  left: 29px;
  border: 1px dashed #686866;
  top: 10px;
  z-index: 0;
}

.partydateHead::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.partytimeLine_head .upperTit {
  font-size: 24px;
  line-height: 26.4px;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.partydateHead h3 {
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
}

.twoBtnsCont button {
  background: #33e17d4d;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 19.84px;
  font-family: "Kumbh Sans", sans-serif;
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.quirkyText p {
  font-family: "Indie Flower", cursive;
  font-size: 16px;
  margin-bottom: 0;
}

.quirkyText {
  position: absolute;
  right: 0;
  top: -25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.twoBtnsCont .approvePending {
  background-color: #fb56074d;
}

.twoBtnsCont .approveRejected {
  background-color: rgb(255, 24, 68);
}

.twoBtnsCont .cancel {
  color: #ae2747;
  background-color: transparent;
}

.partyBgImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.commonpartyInfo {
  gap: 8px;
}

.commonpartyInfo svg {
  width: 24px;
}

.commonpartyInfo p {
  font-size: 16px;
  line-height: 19.84px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
  max-width: 100%;
}

.profileCard {
  border: 1px solid #f8f8f84d;
  background: #33373959;
  border-radius: 24px;
  text-align: center;
}

.profileGridCommon {
  display: grid;
  grid-template-columns: 40% 1fr;
  gap: 3rem;
  margin-top: 7rem;
}

.profileGridCommon.marless {
  margin-top: 3rem;
}

.partiesTimelineCont {
  margin-top: 5rem;
}

.marSpace {
  margin-top: 2rem;
}

.userImgBg img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.maxfitCard {
  max-width: 338px;
}

.profieInner {
  padding: 2rem 1.8rem;
}

.userInfo {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.userIntro h1 {
  line-height: 35.2px;
  font-size: 32px;
  text-transform: uppercase;
}

.userIntro p {
  line-height: 19.8px;
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
}

.userInfo .col h3 {
  font-size: 22px;
  line-height: 24.4px;
  min-height: 27px;
}

.maxWidth {
  width: max-content;
}

.userInfo .col {
  padding: 0;
}

.userInfo .col p {
  font-size: 15px;
  line-height: 19.8px;
  font-family: "Kumbh Sans", sans-serif;
}

.verifiedSection h2 {
  font-size: 20px;
  line-height: 22px;
  font-family: "Kumbh Sans", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
}

.listOfDocs {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.ll {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ll p {
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 20px;
  line-height: 22px;
}

.listCommon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outline {
  border: 2px solid #3f3f46;
  background: transparent;
  color: #fff;
  border-radius: 12px;
  padding: 10px;
  font-size: 16px;
  line-height: 17.6px;
  font-family: "Kumbh Sans", sans-serif;
}

.comIc {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
}

.correctIcon {
  background: #33e17d4d;
}

.wrongIcon {
  background: #fb56074d;
}

.userinputValues {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.squareIconBoxes {
  background: #ffffff1a;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: grid;
  place-items: center;
}

.boxWrapper {
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 50px 1fr;
}

.boxWrapper input {
  margin-bottom: 0;
  border: 2px solid #3f3f46;
  box-shadow: 0px 1px 2px 0px #0000000d;
  background: transparent;
  color: #a1a1aa;
  border-radius: 12px;
  min-height: 48px;
  padding: 20px 10px 0px 10px;
  font-size: 14px;
  font-family: "Kumbh Sans", sans-serif;
}

span.overLabel {
  color: #ff1844;
  font-size: 11.9px;
  line-height: 20px;
  font-family: "Kumbh Sans", sans-serif;
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 600;
}

.userQualitiesCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boxWrapper p {
  margin: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  line-height: 22.32px;
}

.modal-content {
  background-color: #27282c !important;
}

.interestForm .custom-checkbox label {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  line-height: 20px;
}

.custom-checkbox {
  position: relative;
  padding-left: 0 !important;
}

.custom-checkbox label {
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .form-check-label {
  display: inline-block;
  padding: 10px;
  border-radius: 50px;
  background-color: #ffffff1a;
  transition: background-color 0.3s ease;
}

.custom-checkbox input[type="checkbox"]:checked + .form-check-label {
  background-color: white;
  color: #111518;
}

.custom-checkbox .form-check-label:hover {
  background-color: #ffffff4a;
}

.interestForm {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.fakeBadge {
  background-color: #ffffff1a;
  padding: 8px 10px;
  border-radius: 50px;
  line-height: 20px;
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
}

.selectedIntCont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.dashedOutline {
  border: 1px dashed #ffffff80;
  padding: 8px 10px;
  outline: none;
  background-color: transparent;
  color: #fff;
  border-radius: 50px;
  line-height: 20px;
  font-size: 18px;
  font-family: "Kumbh Sans", sans-serif;
}

.profileTitsCom {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 26.4px;
  margin-bottom: 0;
}

.sameMarbtm {
  margin-bottom: 1.5rem;
}

.profileContents {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.pillsFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}

.pillsFooter p {
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
}

.pillsFooter button {
  font-family: "Kumbh Sans", sans-serif;
}

.profImg img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.profileratings {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profNameNStar h4 {
  font-size: 18px;
  line-height: 22.32px;
  font-family: "Kumbh Sans", sans-serif;
}

.ratingText p {
  font-size: 18px;
  line-height: 28.8px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.ratingCard {
  border: 1px solid #f8f8f84d;
  background: #1e2024;
  border-radius: 12px;
  padding: 2rem;
}

.ratingsCont .profileTitsCom {
  margin-top: 3rem;
}

.ratingCardsCont {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 2rem;
}

.guestcardGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 24px;
  margin-bottom: 2rem;
}

.littleBlackCont {
  background-color: #151518;
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  overflow: auto;
  min-height: 230px;
  position: relative;
}

.aboutParty pre {
  color: #fff !important;
  text-wrap: balance !important;
}

.newguestInfoCard {
  border: 1px solid #ffffff3d;
  background-color: #1e2024;
  border-radius: 24px;
  padding: 15px;
  min-height: 122.8px;
  display: grid;
  place-items: center;
}

.guestCardHeader p {
  font-size: 16px;
  line-height: 22.4px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.guestListTable {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 16px;
}

.textLeft {
  text-align: left !important;
}

.guestListTable tr td div,
.guestListTable tr td {
  text-transform: capitalize;
}

.guestCardHeader h3 {
  font-size: 24px;
  line-height: 38.4px;
}

.newguestInfoCard .d-flex {
  gap: 10px;
}

.iconShapeWear {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 12px;
}

.userBlue {
  background-color: #3a86ff33;
}

.thumbsupGreen {
  background-color: #40e48433;
}

.thumbsdownRed {
  background-color: #fd546133;
}

.guestPendingOrange {
  background-color: #fb56072e;
}

.backYellow {
  background-color: #ffe73233;
}

.userStatusBadge {
  padding: 8px;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
}

.userStatusBadge.nApprove span {
  background-color: #d03743;
}

.userStatusBadge.nApprove {
  background-color: #d0374329;
  color: #d03743;
}

.userStatusBadge.approve span {
  background-color: #40e484;
}

.userStatusBadge.approve {
  background-color: #40e48429;
  color: #40e484;
}

.userStatusBadge span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.borderLeftHalf {
  border-radius: 12px 0 0 0;
}

.borderRightHalf {
  border-radius: 0 12px 0 0;
}

.guestSearchCont {
  background-color: #1e2022;
  margin-bottom: 15px;
}

.pending svg {
  stroke: #fdc75f;
}

.failed svg {
  stroke: #d03743;
}

.under-review svg {
  stroke: #3a86ff;
}

.verified svg {
  stroke: #33e17d;
}

.crossContainer {
  cursor: pointer;
}

.upperHead h2 {
  margin-bottom: 0;
  font-size: 20px;
}

.upperHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.hostPartyModalForm label h5 {
  font-size: 14px !important;
  line-height: 18.5px;
  margin-bottom: 0;
}

.button-link {
  text-decoration: none;
  color: inherit;
}

.active-button {
  background-color: white !important;
  color: black !important;
  border-radius: 8px;
}

.inactive-button {
  background-color: gray;
  color: white;
}

.hostPartyModalForm input {
  font-family: "Kumbh Sans", sans-serif !important;
  border: 2px solid #3f3f46;
  background: transparent;
  border-radius: 12px;
  color: #fff;
}

.hostPartyModalForm select {
  font-family: "Kumbh Sans", sans-serif !important;
  border: 2px solid #3f3f46;
  background: #27282c;
  border-radius: 12px;
  color: #fff;
  padding: 6px 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectCont {
  position: relative;
}

.selectCont svg {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 20px;
  pointer-events: none;
}

.pp {
  font-family: "Kumbh Sans", sans-serif !important;
  line-height: 22.8px;
  font-size: 14px !important;
  text-align: left !important;
}

.smolformInfo {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 12px;
  line-height: 16.8px;
  text-align: center;
}

.wfull {
  width: 100%;
}

.cancelCommon {
  padding: 20px 15px;
}

.cancelCommon button {
  font-family: "Kumbh Sans";
  font-weight: 600;
}

.commonPopUpBtn {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.hostPartySubmitBtn {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}

.hostPartyModal .modal-content {
  border: 1px solid #f8f8f84d;
  border-radius: 24px;
}

.hostPartyModal .modal-dialog {
  max-width: 50%;
}

.middlePopupImg {
  max-width: 100px;
  margin: 0 auto;
}

.gotitBoss {
  font-size: 16px;
  line-height: 24px;
  font-family: "Kumbh Sans", sans-serif !important;
  width: 100% !important;
}

.gotItCont {
  padding: 15px;
}

.gotItCont p {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 16px;
  line-height: 25.6px;
}

.gradient-border {
  position: relative;
  border-radius: 24px;
  /* Adjust the radius as needed */
  overflow: hidden;
  /* Ensure child elements stay within the border radius */
}

.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  /* Inherit the border radius from the parent */
  border: 1px solid transparent;
  background: linear-gradient(#000, #000),
    linear-gradient(
      293.86deg,
      #f8f8f8 3.98%,
      #ff4571 25.56%,
      #ffffff 47.15%,
      #7828c8 68.73%,
      #ffffff 90.32%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: -1;
  /* Place the pseudo-element behind the content */
}

.youHaveAlreadyBook {
  padding: 20px;
  background: linear-gradient(
    113.22deg,
    rgba(56, 47, 64, 0.35) 4.59%,
    rgba(84, 38, 130, 0.35) 52.33%,
    rgba(56, 47, 64, 0.35) 79.38%,
    rgba(56, 47, 64, 0.35) 94.66%
  );
  border-radius: 24px;
}

.youHaveAlreadyBook h3 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 1rem;
}

.youHaveAlreadyBook p {
  font-size: 16px;
  line-height: 22.4px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.borderandWidth .modal-content .modal-body {
  padding: 0;
}

.paddlefr {
  padding: 20px;
}

.logSinBgImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stockOut {
  background-color: #333739 !important;
  color: #8a8a88 !important;
  padding: 12px !important;
  margin-bottom: 10px !important;
  font-family: "Kumbh Sans", sans-serif;
}

.crossIconyt {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.commonAmIcon {
  background: #ffffff1a;
  padding: 10px;
  border-radius: 4px;
}

.fakeExpandHead h3,
.aboutParty h3 {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
}

.aboutParty h3 {
  margin-bottom: 1.5rem;
}

.filters-container button {
  color: #fff !important;
}

.betterluckNextTime {
  font-size: 16px;
  line-height: 22.4px;
  text-align: left;
  font-family: "Kumbh Sans", sans-serif;
}

.btnFam button {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
}

.relative {
  position: relative;
}

.commonInputCont label {
  font-size: 18px;
  line-height: 22.5px;
}

.commonInputCont input {
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 2px solid #3f3f46;
  background-color: transparent;
  color: #fff;
  border-radius: 12px;
  font-family: "Kumbh Sans", sans-serif;
}

.commonInputCont select {
  width: 100%;
  display: block;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 2px solid #3f3f46;
  background-color: #27282c !important;
  color: #fff;
  border-radius: 12px;
  padding: 10px 5px;
  font-family: "Kumbh Sans", sans-serif;
}

.commonInputCont button {
  font-family: "Kumbh Sans", sans-serif;
  width: 100%;
  border-radius: 12px;
}

.smolInputInfo {
  font-size: 12px;
  line-height: 16.8px;
  color: #9d9d9b;
  font-family: "Kumbh Sans", sans-serif;
}

.formUpTit h3 {
  font-size: 24px;
  line-height: 26.4px;
}

.formUpTit p {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  line-height: 25.2px;
  margin-bottom: 25px;
}

.twerky p {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 12px;
  line-height: 16.8px;
}

.twerky label {
  font-size: 18px;
  line-height: 22.5px;
}

.inputLoginOtp input {
  flex: 1;
  background: transparent;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 2px solid #3f3f46;
  color: #fff;
  border-radius: 12px;
  outline: none;
  height: 60px;
}

.gridLogSig {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
}

.borderandWidth {
  width: 65% !important;
  max-width: 65% !important;
}

.borderandWidth .modal-content {
  border: 1px solid #f8f8f84d;
  border-radius: 24px;
  overflow: hidden;
}

.spacingtopB {
  padding-top: 4.5rem;
}

.hostHeroCont h1 {
  font-size: 56px;
  line-height: 61.6px;
  text-transform: uppercase;
}

.hostHeroCont p {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  line-height: 28.8px;
}

.hostHeroCont {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}

.img-wrapper.common {
  height: 300px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.promoCont {
  background: #333739;
  border-radius: 12px;
  padding: 0;
  margin-top: 12px;
}

.promoInputCont .relative {
  margin: 10px;
}

.appliedP {
  background: #2d8b2d;
  padding: 5px 15px;
  text-transform: capitalize;
  border-radius: 0 0 10px 10px;
  font-size: 14px !important;
}

.modal-open .modal {
  z-index: 9999;
}

.promoInputCont input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
  padding-left: 30px;
}

.promoInputCont .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ff4571;
}

.removered {
  color: #ff4571 !important;
  font-weight: 600;
}

.promoInputCont button {
  background: transparent;
  border: none;
  outline: none;
  color: #33e17d;
  position: absolute;
  top: 7px;
  right: 5px;
  font-family: "Kumbh Sans", sans-serif;
  text-transform: uppercase;
  line-height: 17.36px;
  font-size: 14px;
}

.promoInputCont {
  position: relative;
}

.middleSearchCont {
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 2px solid #696975;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 100px;
  padding: 10px;
}

.lefInput input::placeholder {
  color: #fff;
}

.padSticky {
  padding: 20px 0 !important;
  background: rgba(16, 17, 21, 0.75);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px 0px #0000001a;
}

.lefInput input {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  margin-bottom: 0;
  border-right: 1px solid #3f3f46;
  font-family: "Kumbh Sans", sans-serif;
  padding: 0;
  min-width: 230px;
}

.iconSearch svg {
  width: 16px;
  height: 16px;
}

.iconSearch {
  background: #ff1844;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}

.happeningInlocation {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.happeningInlocation svg {
  width: 20px;
  height: 20px;
  stroke: #71717a;
}

.happeningInlocation p {
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
}

.maintopTit {
  margin-top: 3rem;
}

.searchNall {
  position: relative;
}

.ytVideodiv {
  position: relative;
}

.ytVideodiv svg {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 75px;
  height: 75px;
  opacity: 0.6;
  cursor: pointer;
}

.city-selector-container {
  background-color: white;
  padding: 20px;
  border-radius: 24px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  min-width: 630px;
  position: absolute;
  top: 60px;
  left: -105px;
  /* transform: translateX(-50%); */
}

.city-selector-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 67%);
  z-index: -1;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.city-option input[type="radio"] {
  display: none;
}

.city-option label {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  color: #000;
  background-color: #f5f4f4;
  font-size: 14px;
  line-height: 20px;
  font-family: "Kumbh Sans", sans-serif;
  border-radius: 8px;
}

.city-option input[type="radio"]:checked + label {
  background-color: #ff18441a;
  color: #ff1844;
  border-radius: 3px;
}

.city-option label:hover {
  background-color: #e0e0e0;
  border-radius: 3px;
}

.contactGrid {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.max80 {
  max-width: 750px !important;
}

.max80 .modal-content {
  background-color: #fff !important;
  color: #000;
  border-radius: 24px;
  overflow: hidden;
}

.max80 .modal-content .modal-body {
  padding: 0 !important;
}

.formContContact input,
.formContContact textarea {
  font-family: "Kumbh Sans", sans-serif;
  border: 2px solid #e8e9f1 !important;
  border-radius: 12px;
  color: #000 !important;
  font-size: 13px;
  resize: none;
}

.formContContact input::placeholder,
.formContContact textarea::placeholder {
  color: #000 !important;
}

.formContContact button {
  font-family: "Kumbh Sans", sans-serif;
}

.bgImgContact {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.416) 63.75%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url("././Frame\ 619.png");
  background-size: cover;
  // background-position: center;
}

.formContContact {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
}

.basicText h3 {
  text-transform: uppercase;
  margin-bottom: 0;
}

.customPadW {
  position: relative;
}

.customPadW input {
  padding-left: 35px;
}

.customPadW img {
  position: absolute;
  top: 5px;
  left: 8px;
  width: 20px;
}

.upperCl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.cursor {
  cursor: pointer;
}

.basicText p {
  color: #494a50;
  font-family: "Kumbh Sans";
  line-height: 22px;
}

.contactInfoAb {
  color: #fff;
}

.contactInfoAb p {
  font-size: 14px;
  line-height: 22.6px;
  font-family: "Kumbh Sans", sans-serif;
  margin-top: 1rem;
}

.contactInfoAb a {
  background-color: rgb(29 29 29 / 70%);
  padding: 10px;
  border-radius: 10px;
  // margin-right: 15px;
}

// .leftPicCont {
//   position: relative;
//   /* Add other styles for the left container */
// }

.leftPicCont {
  position: relative;
  height: 100%;
  width: 400px;
  min-height: 500px;
}

.rightAnimate {
  flex: 1;
}

.leftImage {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.leftImage.active {
  opacity: 1;
}

.animateinner {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.singleInfo.active .infoText h4 {
  color: #fff;
}

.singleInfo.active .infoText p {
  color: #656565;
}

.circleIndicator {
  border-radius: 50%;
  background: transparent;
  transition: background 1s ease-in-out;
}

.singleInfo.active .circleIndicator {
  background: #fff;
}

.footerCont {
  font-family: "Kumbh Sans", sans-serif;
}

.footer-nav h3 {
  font-size: 14px;
  line-height: 22.4px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-nav li a {
  font-size: 14px;
  line-height: 22.4px;
  padding: 0;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.social {
  gap: 1rem;
}

.resendOtp {
  font-size: 16px;
  line-height: 24px;
  font-family: "Kumbh Sans", sans-serif;
  text-align: center;
}

.aboutParty p {
  font-size: 18px;
  line-height: 28.8px;
  font-family: "Kumbh Sans", sans-serif;
  text-transform: capitalize;
}

.innerBox button {
  background: transparent;
  color: #71717a;
  border: none;
  outline: none;
  font-family: "Kumbh Sans", sans-serif;
  padding: 5px 10px;
}

.hostNavbar {
  border: 2px solid #3f3f46;
  padding: 5px;
  border-radius: 12px;
}

.payOutForm {
  font-family: "Kumbh Sans", sans-serif;
}

form.payOutForm label {
  font-size: 18px;
  line-height: 19.8px;
}

form.payOutForm input {
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 2px solid #3f3f46;
  border-radius: 12px;
  background: transparent;
  padding: 20px 10px;
}

.partyImageGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  border-radius: 12px;
  overflow: hidden;
}

.partyImageGrid.single {
  grid-template-columns: 1fr;
  max-height: 450px;
}

.userIntro {
  margin-top: 1rem;
}

.partyImageGrid.single div {
  height: 100%;
  max-height: 450px;
}

.partyImagesCont {
  margin-bottom: 3rem;
}

.partyImageGrid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workaroundFlex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.singleBank {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.5px solid #333739;
  padding-bottom: 2rem;
  max-width: 480px;
}

.bankIcon {
  background: #40e48440;
  color: #40e484;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: grid;
  place-items: center;
}

.listOfBanks {
  margin-top: 3rem;
}

.bankIcon svg {
  width: 30px;
  height: 30px;
}

.bankDetails_cont h5 {
  font-size: 20px;
  line-height: 28px;
  font-family: "Kumbh Sans", sans-serif;
  margin-bottom: 0;
}

.bankDetails_cont p {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px !important;
  line-height: 19.6px !important;
  color: #676162 !important;
  margin-bottom: 0;
}

.payoutHero p {
  color: #a1999b;
  font-size: 16px;
  line-height: 25.6px;
  font-family: "Kumbh Sans", sans-serif;
}

.payoutHero h1 {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 35.2px;
  margin-bottom: 4rem;
}

.expanderCont.border-bottom {
  border-bottom: 1px solid #3f3f464d !important;
}

.ytVideodiv img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.disNope {
  display: none;
}

.widthFull {
  width: 100% !important;
}

.logoContBrands img {
  width: 100%;
  /* height: 100%; */
  max-width: 110px;
}

.whatsappImgAb {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 20px;
  transform: translateY(-50%);
}

.guestHead h2 {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 30px;
}

.guestTit {
  display: flex;
  align-items: center;
  gap: 10px;
}

.guestHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.guestHead button {
  border: none;
  background: transparent;
  color: #fd5461;
  outline: none;
}

.guestWhatsapp p {
  font-family: "Kumbh Sans", sans-serif;
}

input[internal-autofill-selected] {
  background-color: #27272a !important;
}

.nonSticky {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  background: #1f2224;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.nonSticky.show::before {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #161719c9;
  backdrop-filter: blur(5px);
}

.closeBottomSheet {
  position: relative;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeBottomSheet span {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  color: #000;
  place-items: center;
}

.closeBottomSheet span svg {
  width: 20px;
}

.nonSticky.show {
  bottom: 0;
}

.guestsWrapper.purely {
  background: #27282c;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 24px;
  border-radius: 16px;
}

.cutomBackButton svg {
  width: 20px;
}

.cutomBackButton {
  width: 30px;
  height: 30px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 999;
}

.topFakeNav p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
}

.topFakeNav {
  background: #27282c;
  display: flex;
  align-items: center;
  padding: 15px;
  gap: 10px;
}

.mob2 {
  margin-top: 2rem !important;
}

.marMore {
  margin-top: 7rem;
}

/* Custom CSS for slider dots */
.slick-dots {
  bottom: 45px !important;
  /* Adjust position as needed */
}

.slick-dots li {
  width: 10px;
  /* Size of the dots */
  height: 10px;
  /* Size of the dots */
  margin: 0 5px;
  /* Space between dots */
}

.slick-dots li button {
  font-size: 0;
  /* Remove default button text */
  border: none;
  /* Remove default border */
  background: transparent;
  /* Remove default background */
}

.slick-dots li button:before {
  content: "";
  /* Remove default content */
  display: block;
  /* Ensure it behaves like a block element */
  width: 10px;
  /* Size of the dots */
  height: 10px;
  /* Size of the dots */
  border-radius: 50%;
  /* Make dots circular */
  background-color: black;
  /* Background color for inactive dots */
  opacity: 0.5;
  /* Slight transparency for inactive dots */
  transition: background-color 0.3s, opacity 0.3s;
  /* Smooth transition */
}

.slick-dots li.slick-active button:before {
  background-color: white;
  /* Background color for active dot */
  opacity: 1;
  /* Full opacity for the active dot */
}

.staticApply {
  background: #33e17d4d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  margin-top: 12px;
  border-radius: 6px;
}

.newOutLook {
  margin-bottom: 1.5rem;
}

.newOutLook .topEvPrice,
.newOutLook .guestCountOp {
  margin-bottom: 0 !important;
  background: transparent;
  padding: 0;
}

.staticApply div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.staticApply p {
  margin-bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
}

.fade.modal.show {
  background: #00000063;
}

.payNowCont button {
  font-family: "Kumbh Sans", sans-serif;
}

.payNowCont p {
  font-family: "Kumbh Sans", sans-serif;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  margin-top: 10px;
  display: inline-flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.payNowCont p a {
  font-weight: 600;
  color: #fff;
  text-decoration: underline !important;
  text-underline-offset: 2px;
}

.payNowCont {
  text-align: center;
  margin-top: 1.5rem;
}

.ratingCardsCont.modalOne .ratingText p {
  font-size: 16px;
  line-height: 24.8px;
}

.ratingCardsCont.modalOne .profNameNStar h4 {
  font-size: 18px;
  line-height: 22.32px;
  margin-bottom: 5px;
}

.ratingCardsCont.modalOne span.star svg {
  width: 20px;
}

.amenityAns.row span.mx-3 {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
}

.bgOverlay img {
  width: 24px;
}

.bgOverlay {
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.bgOverlay.negative {
  background-color: #ff184540;
}

.bgOverlay.positive {
  background-color: #40e4841a;
}

.userImgBg .relative {
  max-width: 120px;
  margin: 0 auto;
}

.editDp {
  position: absolute;
  top: 0;
  right: 0;
  background: #ff1844;
  padding: 5px;
  border-radius: 50%;
}

.akas {
  background: #fff;
  color: #131418;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 0;
}

.akas p {
  font-weight: 600;
  font-size: 9px !important;
  line-height: 16px !important;
  margin-bottom: 0;
  font-family: "Krona One", sans-serif !important;
}

.disclamhm {
  color: #c9c9c9;
  font-size: 14px;
  font-family: "Kumbh Sans";
  line-height: 20px;
}

.padtopMobile {
  padding-top: 1rem;
}

.noPartiesFound {
  margin-top: 3rem;
}

.gridVideos {
  display: flex;
  padding: 10px;
  gap: 15px;
}

.extraFontsCont ol {
  padding-left: 15px;
}

.tempImgS {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
}

.staticImageShow img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

.staticPrevImg img {
  min-height: 294px;
  width: 100%;
  max-width: 525px;
  object-fit: cover;
}

.staticPrevImg {
  display: grid;
  place-items: center;
}

span.outOf {
  font-size: 16px;
}

.rateC {
  justify-content: center;
  gap: 5px;
}

.d-flex.rateC svg {
  width: 22px;
}

.leftUnit {
  position: relative;
}

.platformFeeToolTip {
  position: absolute;
  z-index: 9;
  top: 35px;
  right: -12px;
  width: 180px;
  background: #464c4f;
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  transition: 0.15s;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.platformFeeToolTip::after {
  content: "";
  position: absolute;
  top: -15px;
  right: 7px;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #464c4f transparent;
}

.toolShow:hover .platformFeeToolTip {
  opacity: 1;
}

.platformFeeToolTip span,
.platformFeeToolTip .toolLeftInner p {
  font-size: 14px;
  font-family: "Kumbh Sans", sans-serif;
}

.toolShow p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toolShow p svg {
  stroke: #838383;
}

.wholeScreenCover {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: #161719c9;
  backdrop-filter: blur(5px);
  z-index: 2005 !important;
  transition: right 0.35s ease-in-out;
  // right: -100%;
}

.wholeScreenCover.opened {
  right: 0;
}

.copounsBoxOuter {
  max-width: 450px;
  background: #27282c;
  margin-left: auto;
  height: 100%;
  padding: 20px;
}

.titleAb h2 {
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Kumbh Sans", sans-serif;
  background: #27282c;
  width: fit-content;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
}

.titleAb {
  margin: 1.7rem 0;
  position: relative;
}

.titleAb::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #333739;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.couponBox {
  background: #333739;
  padding: 20px;
  border-radius: 12px;
}

.couponDesc p {
  font-family: "Kumbh Sans", sans-serif;
  line-height: 22px;
}

.couponHeader {
  margin-bottom: 1rem;
}

.couponDesc .d-flex {
  flex-wrap: wrap;
}

.staticBorderRaccon img {
  width: 100%;
  height: 100%;
}

.staticBorderRaccon {
  width: 35px;
  height: 35px;
  padding: 5px;
  border: 1px dashed #ff1844;
  border-radius: 7px;
}

.couponDetails {
  display: flex;
  align-items: center;
}

.couponDesc button {
  font-family: "Kumbh Sans", sans-serif;
}

.couponNameBorder h4 {
  margin: 0;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
}

.couponNameBorder {
  padding: 5px;
  border: 1px dashed #ff1844;
  border-radius: 7px;
  height: 35px;
  display: grid;
  place-items: center;
}

.couponHeader p {
  margin: 0;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
}

.heightInc {
  height: 45px;
}

.topMor {
  top: 12px !important;
}

p.statApply {
  text-align: left;
  padding-left: 35px;
  font-size: 14px;
  cursor: pointer;
}

.couponList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  max-height: 70vh;
}

.couponDesc button:disabled {
  background-color: #71717a !important;
  color: #3f3f46 !important;
  border-color: transparent;
}

.couponList::-webkit-scrollbar,
.overFlow::-webkit-scrollbar {
  width: 10px;
}

.couponList::-webkit-scrollbar-track,
.overFlow::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.couponList::-webkit-scrollbar-thumb,
.overFlow::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #2c2c2c;
}

// .couponList::-webkit-scrollbar-thumb:hover,
// .overFlow::-webkit-scrollbar-thumb:hover {
//   background-color: #333;
// }

.overFlow {
  overflow: auto;
  max-height: calc(100vh - 95px);
  padding-bottom: 2rem;
  padding-right: 1rem;
  min-height: 85vh;
  position: relative;
}

.closeIcc {
  margin-bottom: 2rem;
  width: fit-content;
  cursor: pointer;
}

.partyImgBg {
  max-height: 233px;
  overflow: hidden;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.partyImgBg img {
  width: 100%;
  height: 100%;
  max-height: 233px;
  object-fit: cover;
}

.statusMarker {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 2;
  display: grid;
  place-items: center;
}

.statusMarker::after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #454b4e;
  outline: 6px solid #454b4e66;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  z-index: -1;
}

.statusMarker.greenFlag::after {
  background: #40e484;
  outline: 6px solid #40e48466;
}

.statusMarker.redFlag::after {
  background: #ff1844;
  outline: 6px solid #ff184466;
}

.statusMarker::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 70px;
  border: 1px dashed #848484;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
}

.partyStatsCont .statInd:last-of-type .statusMarker::before {
  display: none;
}

.statusMarker svg {
  width: 13px;
  height: 18px;
}

.statInd {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 6px;
}

.partyStatsCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statusNamenDate p {
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
  font-family: "Kumbh Sans", sans-serif;
  line-height: 19.8px;
}

.statusNamenDate span {
  font-size: 14px;
  color: #71717a;
  font-family: "Kumbh Sans", sans-serif;
}

.addressRevealed {
  padding: 20px;
  border-radius: 12px;
  margin-top: 2.5rem;
  z-index: 1;
}

.addressRevealed.gradient-border {
  border-radius: 12px;
}

.addressRevealed.gradient-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  border: 1px solid transparent;
  background: linear-gradient(#27282c, #27282c),
    linear-gradient(
      293.86deg,
      #f8f8f8 3.98%,
      #ff4571 25.56%,
      #ffffff 47.15%,
      #7828c8 68.73%,
      #ffffff 90.32%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: -1;
}

// .couponList {
//   scrollbar-width: thin;
//   scrollbar-color: #1a1a1a #2c2c2c;
// #fefefe #3c3c3c
// }

.adContxt p {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
  margin: 0;
  line-height: 25.6px;
}

.iconAb {
  background: #7828c840;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 8px;
}

.iconAb svg {
  width: 16px;
  stroke: #8c3dfb;
}

.adContxt {
  flex: 1;
}

.mapDir a svg {
  fill: #fff;
}

.innerAddressCont {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.innerAddressCont .d-flex {
  gap: 15px;
}

.adContxt span {
  color: #a1a1aa;
  font-size: 14px;
  font-family: "Kumbh Sans", sans-serif;
}

.innerAddressCont.special .d-flex {
  gap: 5px !important;
}

.innerAddressCont.special .iconAb {
  background: transparent !important;
}

.innerAddressCont.special .iconAb svg {
  width: 24px !important;
}

.mtsmall {
  margin: 0.5rem !important;
}

.mapDir a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3a86ff !important;
  font-size: 13px;
  font-weight: 400;
  font-family: "Kumbh Sans", sans-serif;
  gap: 7px;
  cursor: pointer;
}

.mapDir a span {
  display: inline-grid;
  width: 27px;
  height: 27px;
  border: 1px solid #fff;
  border-radius: 50%;
  place-items: center;
}

.mapDir a span img {
  width: 13px;
}

.noPat {
  text-align: center;
  max-width: 610px;
  margin: 0 auto;
}

.noPat h2 {
  font-size: 20px;
  text-transform: uppercase;
}

.noPat p {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
}

.flexVertif {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 15px;
}

.flexVertif .see-all-button {
  border: 2px solid #fff;
  padding: 5px 12px;
  font-size: 14px;
  margin-right: auto;
  margin-left: 0;
}

.loaderCont {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
}

.customLoader {
  width: 40px;
  height: 20px;
  --c: no-repeat radial-gradient(farthest-side, #fff 93%, #fff);
  background: var(--c) 0 0, var(--c) 50% 0, var(--c) 100% 0;
  background-size: 8px 8px;
  position: relative;
  animation: l4-0 1s linear infinite alternate;
}

.customLoader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  background: #fff;
  left: 0;
  top: 0;
  animation: l4-1 1s linear infinite alternate,
    l4-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

.noFilData {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.arrrowsCont .slick-next {
  top: -60px;
  right: 35px;
}

.arrrowsCont .slick-prev {
  top: -60px;
  right: 75px;
  left: auto;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

p.amountD {
  font-family: "Krona One", sans-serif;
  margin: 0;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  text-transform: uppercase;
}

@keyframes l4-0 {
  0% {
    background-position: 0 100%, 50% 0, 100% 0;
  }

  8%,
  42% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  50% {
    background-position: 0 0, 50% 100%, 100% 0;
  }

  58%,
  92% {
    background-position: 0 0, 50% 0, 100% 0;
  }

  100% {
    background-position: 0 0, 50% 0, 100% 100%;
  }
}

@keyframes l4-1 {
  100% {
    left: calc(100% - 8px);
  }
}

@keyframes l4-2 {
  100% {
    top: -0.1px;
  }
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

// *********************************************
@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: auto auto;
  }

  .party-card-heart {
    position: absolute;
    top: 3%;
    left: 82%;
  }
}

@media screen and (max-width: 992px) {
  .slider {
    margin: -2.5rem 0 0 0;
  }

  .parties-heart {
    position: absolute;
    top: 2%;
    left: 80%;
  }

  .attend-section {
    &--container {
      padding-left: 130px !important;
    }
  }

  .spaces-heart {
    position: absolute;
    top: 50%;
    left: 85%;
  }

  .security-section {
    position: relative;
  }

  .timeplace {
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }

  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .filters-container {
    margin-top: 1rem;
  }
}

.rcs_slideCon .itemWrapper img {
  border-radius: 6px;
}

@media screen and (max-width: 776px) {
  .slider {
    margin: -2.5rem 0 0 0;
  }

  .parties-heart {
    position: absolute;
    top: 3%;
    left: 90%;
  }

  .spaces-heart {
    position: absolute;
    top: 75%;
    left: 85%;
  }

  .photo-carousel {
    .carousel-inner {
      // width: 63%;
      margin: 0 auto;
      height: auto;

      .carousel-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top: 1em;
      }
    }
  }

  .security-section {
    position: relative;
  }

  .filters-container {
    margin-top: 1rem;
    float: none;
    display: flex;
    justify-content: flex-end;
    // flex-wrap: wrap;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 576px) {
  .d-flex.rateC svg {
    width: 18px;
  }

  .arrrowsCont .slick-next {
    top: -45px;
    right: 35px;
  }

  .arrrowsCont .slick-prev {
    top: -45px;
    right: 65px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 23px !important;
  }

  .noFilData {
    bottom: 10px;
    width: 100%;
    font-size: 16px;
  }

  .littleBlackCont {
    min-height: 300px;
  }

  .noPat p {
    font-size: 14px;
  }

  .noPat h2 {
    font-size: 16px;
  }

  .noPat img {
    max-width: 140px;
    margin-bottom: 1.5rem;
  }

  img.pastImages {
    max-width: 100%;
    max-height: 200px;
    min-width: 200px;
    object-fit: cover;
  }

  .rcs_slideCon .itemWrapper {
    max-height: 200px;
  }

  span.outOf {
    font-size: 14px;
  }

  .staticPrevImg {
    margin-top: 1rem;
  }

  .staticImageShow img {
    width: 100%;
    max-height: 300px;
  }

  h2.partiesHostedTit {
    font-size: 20px;
    margin-bottom: 2rem;
  }

  h2.partiesHostedTit.mobmbzero {
    margin-bottom: 0;
  }

  .tempImgS {
    padding: 0 10px;
  }

  .brandsCar_cont {
    margin-top: 5rem;
  }

  .middleSearchCont {
    gap: 5px;
  }

  .lefInput input {
    min-width: 205px;
  }

  .contactGrid {
    grid-template-columns: 1fr;
  }

  .bgImgContact {
    display: none;
  }

  .memReachToolTip::after {
    bottom: -16px;
    right: 0;
  }

  .toolTipReach.show:hover .memReachToolTip {
    top: -55px;
    right: 0;
  }

  footer#padeightRem {
    padding-bottom: 9rem;
  }

  .logoContBrands img {
    max-width: 60px !important;
  }

  .titleMid {
    margin-bottom: 2rem;
  }

  .kumbh {
    padding-top: 2rem;
  }

  .ytVideodiv {
    position: relative;
    min-width: 65%;
  }

  .guestSearchCont {
    flex-direction: column;
    gap: 1.5rem !important;
    padding: 10px !important;
  }

  .guestSearchCont .widthFifty {
    width: 100% !important;
  }

  // footer {
  //   padding-bottom: 10rem;
  // }

  .youHaveAlreadyBook p {
    font-size: 12px;
    line-height: 18.4px;
  }

  .youHaveAlreadyBook h3 {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0.5rem;
  }

  .infoText p {
    color: #fff;
    line-height: 22px;
    font-size: 14px;
  }

  .infoText h4 {
    color: #fff;
  }

  .singleInfo {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .imgContVerify img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 300px;
    border-radius: 30px;
  }

  .nopartyFallBack img {
    width: 90px;
    margin-bottom: 2rem;
  }

  .nopartyFallBack p {
    font-size: 12px;
    font-family: "Kumbh Sans", sans-serif;
    line-height: 16px;
  }

  .noPartiesFound {
    margin-top: 2rem;
  }

  .nopartyFallBack h3 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  .gotItCont p {
    font-family: "Kumbh Sans", sans-serif !important;
    font-size: 14px;
    line-height: 22.6px;
  }

  .betterluckNextTime {
    font-size: 14px !important;
    line-height: 19.4px;
    text-align: center;
  }

  .upperHead h2 {
    font-size: 18px;
  }

  .hostPartyModalForm .row {
    flex-direction: column;
  }

  .footer-nav h3 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .footerCont .col-md-2 {
    margin-bottom: 2rem;
  }

  .firstTimeFlow {
    margin-top: 3rem;
  }

  .mobileTopone {
    margin-top: 1rem !important;
  }

  .quirkyText p {
    font-size: 14px;
  }

  .boxWrapper {
    gap: 15px;
    grid-template-columns: auto 1fr;
  }

  .boxWrapper p {
    font-size: 14px;
    line-height: 20.32px;
  }

  .squareIconBoxes {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  .profileTitsCom {
    font-size: 18px;
    line-height: 22.4px;
  }

  .fakeBadge {
    padding: 7px 9px;
    line-height: 18px;
    font-size: 14px;
  }

  .selectedIntCont {
    gap: 13px;
  }

  .dashedOutline {
    line-height: 16px;
    font-size: 14px;
  }

  .squareIconBoxes svg {
    width: 20px;
  }

  .ll {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .ll p {
    margin-bottom: 0;
    font-family: "Kumbh Sans", sans-serif;
    font-size: 16px;
    line-height: 20px;
  }

  .comIc svg {
    width: 18px;
  }

  .verifiedSection h2 {
    font-size: 16px;
    line-height: 20px;
    font-family: "Kumbh Sans", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
  }

  .userInfo .col p {
    font-size: 12px;
    line-height: 15.8px;
    font-family: "Kumbh Sans", sans-serif;
  }

  .userInfo .col h3 {
    font-size: 18px;
    line-height: 22.4px;
    min-height: 24px;
  }

  .userIntro h1 {
    line-height: 28.2px;
    font-size: 24px;
    text-transform: uppercase;
  }

  .userIntro p {
    line-height: 16.8px;
    font-size: 14px;
    font-family: "Kumbh Sans", sans-serif;
  }

  .userImgBg img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .fade.modal.show {
    padding-right: 0 !important;
  }

  .cancelCommon p {
    font-size: 12px;
    line-height: 20.6px;
    font-family: "Kumbh Sans";
    margin-bottom: 0;
  }

  .bgOverlay p {
    text-align: left;
  }

  .cancelCommon button {
    font-family: "Kumbh Sans";
    font-weight: 600;
    padding: 10px 0;
  }

  .amenityAns.row span.mx-3 {
    font-size: 14px;
  }

  .amenityAns.row .my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .ratingCardsCont.modalOne span.star svg {
    width: 16px;
  }

  .ratingCardsCont.modalOne .profNameNStar h4 {
    font-size: 16px;
    line-height: 20.32px;
    margin-bottom: 5px;
  }

  .ratingCardsCont.modalOne .profImg img {
    width: 40px;
    height: 40px;
  }

  .ratingCardsCont.modalOne .profileratings {
    margin-bottom: 10px;
  }

  .ratingCardsCont.modalOne .ratingText p {
    font-size: 12px;
    line-height: 22.8px;
  }

  .flexUpCont .profileTitsCom {
    font-size: 16px;
    line-height: 20.4px;
  }

  .ratingCardsCont.modalOne .ratingCard {
    border-radius: 10px;
    padding: 1rem;
  }

  .ratingCardsCont.modalOne {
    max-height: 60vh;
  }

  .outlineGray {
    font-size: 15px !important;
    line-height: 18px !important;
    padding: 8px 10px !important;
    gap: 10px;
  }

  .inputLoginOtp {
    display: grid !important;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: 12px;
  }

  h4.make-pointer {
    font-size: 16px;
  }

  .inputLoginOtp input {
    border-radius: 10px;
    height: 45px;
    font-size: 14px;
    flex: 1;
    /* aspect-ratio: 1 / 1; */
    width: 100%;
  }

  p.resendOtp {
    font-size: 16px;
    line-height: 24px;
    margin: 0 !important;
    padding: 16px 10px 16px 10px;
    margin-top: 0 !important;
  }

  .twerky label {
    font-size: 16px;
    line-height: 20.5px;
  }

  .commonInputCont label {
    font-size: 14px;
    line-height: 18.5px;
  }

  .formUpTit h3 {
    font-size: 18px;
    line-height: 22.4px;
    text-align: center;
  }

  .formUpTit.noCenter h3 {
    text-align: left !important;
  }

  .commonInputCont button {
    margin-top: 2rem !important;
  }

  form.commonInputCont.mt-5 {
    margin-top: 2rem !important;
  }

  .paddlefr {
    padding: 30px 0;
  }

  .gridLogSig .relative {
    display: none;
  }

  .gridLogSig {
    grid-template-columns: 1fr;
  }

  .partytimeLine_head .upperTit {
    font-size: 18px;
    line-height: 22.4px;
    margin-bottom: 1.5rem;
  }

  .padAd {
    padding: 0;
  }

  .spacingtopB {
    padding-top: 13.5rem;
  }

  .partydateHead h3 {
    font-size: 16px;
    line-height: 18px;
  }

  h2.titleParty {
    line-height: 20px;
    font-size: 14px;
  }

  .partyTimelineDetailsCont {
    padding: 10px;
  }

  .commonpartyInfo svg {
    width: 16px;
  }

  .partyBgImg img {
    max-height: 165px;
  }

  .partyBgImg {
    max-height: 165px;
    overflow: hidden;
  }

  .partydateHead {
    margin-bottom: 1rem;
  }

  .hostNavbar {
    order: 3;
    width: 100%;
  }

  .innerBox {
    display: grid;
    grid-template-columns: auto auto;
  }

  .suctit {
    line-height: 18.4px;
    font-size: 16px;
  }

  .eventDetailsSuccess {
    padding: 15px;
    margin-top: 1rem;
  }

  button.whiteFull {
    font-size: 13px;
    line-height: 18px;
    border-radius: 10px;
    margin-top: 1rem;
  }

  .profileVerificationCont button {
    font-size: 13px;
    line-height: 18px;
    border-radius: 10px;
  }

  .profileVerificationCont h4 {
    font-size: 12px;
    line-height: 16.6px;
  }

  .eventDetailsSuccess .flexCenter {
    flex-direction: column;
  }

  .smolCardImg {
    width: 100%;
    height: 130px;
    border-radius: 8px;
  }

  .marMore {
    margin-top: 0;
  }

  h2.titleStack {
    font-size: 20px;
    line-height: 23.8px;
  }

  .locationNdate svg {
    width: 16px;
  }

  .rightEventDetails .flexCenter {
    flex-direction: row;
    gap: 12px;
  }

  .rightEventDetails .flexCenter {
    flex-direction: row;
  }

  .partyImageGrid {
    border-radius: 0;
    margin-bottom: 1.5rem;
  }

  .specialTop {
    margin-top: 12rem;
  }

  .guestTopTit {
    font-size: 14px;
  }

  .iconShapeWear {
    width: 35px;
    height: 35px;
    border-radius: 10px;
  }

  .iconShapeWear svg {
    width: 20px;
  }

  .guestCardHeader p {
    font-size: 14px;
    line-height: 20.4px;
  }

  .guestCardHeader h3 {
    font-size: 20px;
    line-height: 30.4px;
  }

  .guestcardGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .newguestInfoCard {
    border-radius: 15px;
    padding: 10px;
    min-height: 122.8px;
  }

  .city-selector-container {
    padding: 10px;
    border-radius: 12px;
    gap: 10px;
    min-width: 100%;
    top: 60px;
    left: 0;
  }

  .city-option label {
    padding: 5px 7px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
  }

  .infoWine {
    bottom: -110px;
    right: 0;
  }

  .profileClicked {
    border-radius: 6px;
    padding: 5px 0;
  }

  .profileClicked a {
    font-size: 14px;
    line-height: 18px;
  }

  .infoDisco {
    left: 0;
    top: -90px;
    width: 80px;
  }

  .bgVideoLoop_stack {
    min-height: 40vh;
  }

  .guestHead h2 {
    font-size: 16px;
  }

  .inputContsFill label {
    font-size: 12px;
  }

  .nonSticky .sticky-ticket {
    position: relative !important;
    top: 0 !important;
  }

  .navbar-brand {
    margin-right: 0 !important;
  }

  .profileContents .row {
    flex-direction: column;
    gap: 1rem;
  }

  .userinputValues {
    gap: 1rem;
  }

  .custom-checkbox .form-check-label {
    padding: 8px 10px;
    border-radius: 50px;
  }

  .interestForm .custom-checkbox label {
    font-size: 14px;
    line-height: 16px;
  }

  .modal-title.h4 {
    font-size: 18px;
    line-height: 22px;
  }

  .profileGridCommon {
    grid-template-columns: 1fr;
  }

  .leftSide {
    min-width: 100% !important;
  }

  .covertimeline {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .partydateHead::before {
    left: -35px;
  }

  .timeLineDottedCont::after {
    left: 15px;
  }

  .partyDateCont {
    padding-left: 40px;
  }

  .hostPartyModal .modal-dialog {
    max-width: 100%;
  }

  .expanderCont.border-bottom {
    margin-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .kumBh {
    font-size: 12px !important;
    line-height: 20.8px !important;
  }

  .centimg {
    display: none;
  }

  .expanderCont.border-bottom svg {
    width: 20px;
  }

  .commonAmIcon {
    padding: 5px;
    border-radius: 4px;
  }

  .fakeExpandHead span.mx-3 {
    font-size: 12px;
  }

  .expanderCont h3 {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .topText p {
    font-size: 24px;
    line-height: 32px;
  }

  .middleBanner {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    text-align: center;
  }

  .happeningInlocation p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 54px;
  }

  .hostHeroCont h1 {
    font-size: 32px;
    line-height: 38.6px;
  }

  .hostHeroCont {
    width: 90%;
  }

  .titleMid h2 {
    font-size: 20px;
  }

  .videoEx video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .videoEx {
    max-height: 430px;
  }

  .videosCont {
    grid-template-columns: 1fr;
  }

  .verifyGrid {
    flex-direction: column;
  }

  .leftPicCont {
    width: 100% !important;
    min-height: 380px;
  }

  .circleIndicator {
    width: 35px;
    height: 35px;
  }

  .singleInfo h4 {
    font-size: 20px;
  }

  .oddText p {
    font-size: 16px;
  }

  .gridParty {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .bgVideoLoop_stack h2 {
    font-size: 24px;
    line-height: 34.8px;
  }

  .eventTit h3 {
    font-size: 16px;
  }

  .heroText h1 {
    font-size: 32px;
  }

  .heroText {
    gap: 35px;
    text-align: center;
    align-items: center;
  }

  .topGradient,
  .bottonGradient {
    z-index: -1;
  }

  a.navbar-brand img {
    width: 110px;
  }

  .padSticky {
    padding: 20px !important;
  }

  .padSticky .container {
    gap: 1rem;
  }

  .searchNall {
    order: 3;
    width: 100%;
  }

  .profile_cont {
    gap: 10px;
    order: 1;
  }

  .profile_cont button a {
    gap: 10px;
    font-size: 12px;
  }

  .profile_cont svg {
    width: 16px;
  }

  .aboutParty h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .aboutParty p {
    font-size: 12px;
  }

  .partiesHead {
    font-size: 18px;
  }

  .mTop {
    margin-top: 5rem;
  }

  .topEvPrice h5 {
    font-size: 18px !important;
    line-height: 24px;
  }

  .sticky-ticket .text-center.px-4.pt-3.pb-4 {
    padding: 16px !important;
  }

  .guestCountOp p {
    font-size: 12px;
    line-height: 20.4px;
  }

  .countCont button svg {
    width: 14px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .guestCountOp button {
    border-radius: 10px;
    display: grid;
    height: 30px;
    place-items: center;
    width: 30px;
    position: relative;
  }

  .guestsWrapper.purely {
    padding: 16px;
  }

  .unitEconomicsCont p {
    font-size: 14px;
    line-height: 20.4px;
  }

  .promoCont {
    border-radius: 10px;
    // padding: 10px;
  }

  .unitEconomicsCont {
    gap: 10px;
  }

  .guestCountOp {
    margin-bottom: 1rem;
  }

  .topEvPrice {
    margin-bottom: 1.5rem;
  }

  .finaltotal .rightUnit p {
    font-size: 18px;
    line-height: 24.6px;
  }

  .guestListInputs {
    border-radius: 16px;
    padding: 16px;
  }

  .locationNdate {
    flex-direction: column;
    align-items: baseline;
    gap: 0px;
  }

  button.mobile_booking {
    background: #ff1844;
    color: #fff;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    margin-top: 15px;
  }

  .bottomSelling {
    box-shadow: 0px -1px 25px 0px #ff184480;
    border-radius: 12px 12px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: #1e202459;
    padding: 15px;
    font-family: "Kumbh Sans", sans-serif;
    backdrop-filter: blur(15px);
  }

  .partyImageGrid.single {
    grid-template-columns: 1fr;
    max-height: 285px;
    min-height: 285px;
  }

  .partyImageGrid.single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 285px;
    min-height: 285px;
  }

  .leftmobBook {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .leftmobBook p {
    margin-bottom: 0;
  }

  .bottomSelling p {
    margin: 0;
  }

  .innerSelling .countCont button {
    position: relative;
    background: #494a50;
    color: #fff;
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    padding: 0;
    display: grid;
    place-items: center;
  }

  .innerSelling .countCont button svg {
    width: 16px;
  }

  .whatsappNumForm button {
    line-height: 22px;
    font-size: 14px;
  }

  form.whatsappNumForm .row {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 12px;
  }

  form.whatsappNumForm .row .col {
    padding: 0;
  }

  .otpInputs {
    border-radius: 10px !important;
    margin-bottom: 5px;
    color: #fff !important;
    font-size: 14px !important;
  }

  .newFlowRevam h3 {
    margin-bottom: 1rem;
    font-size: 18px;
  }

  .smallIcon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .partyHeading .eventDetGap {
    font-size: 16px !important;
    line-height: 20.32px !important;
  }

  .slider {
    margin: -1rem 0 0 0;
  }

  .ahe-img {
    width: 35%;
    margin-left: -1.5rem;
  }

  .parties-heart {
    position: absolute;
    top: 3%;
    left: 86%;
  }

  .spaces-heart {
    position: absolute;
    top: 75%;
    left: 80%;
  }

  .security-section {
    position: relative;
  }

  .timeplace {
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }

  .footer-nav {
    padding-left: 0;
  }

  .attend-section > .row {
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  .row::-webkit-scrollbar {
    width: 0 !important;
  }

  .badge-price {
    flex-wrap: wrap;
  }

  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-rows: 1fr;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .filters-container {
    margin: 5rem auto 2rem auto;
    width: 100%;
  }

  .footer-form {
    margin: 1rem 1rem 0 1rem;
  }
  .payNowCont p {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 391px) {
  .innerSelling .countCont button svg {
    width: 14px !important;
  }
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.modal-header {
  border-bottom: none;
}

.modal-dimensions {
  width: 50%;
  height: 50%;
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .modal-dimensions {
    width: 100%;
    height: 50%;
  }
}

@media screen and (min-width: 1450px) {
  .parties-heart {
    position: absolute;
    top: 3%;
    left: 90%;
  }

  .spaces-heart {
    position: absolute;
    top: 85%;
    left: 88%;
  }

  // .container {
  //   max-width: 1720px;
  // }

  .img-wrapper {
    height: 300px;
  }
}

.onfido-sdk-ui-Theme-modalOverlay {
  z-index: 9999999;
}

// .kep-login-facebook {
// 	// opacity: 0;
// }

.loader {
  border: 2px solid #f3f3f3;
  /* Light grey */
  border-top: 2px solid #ff1844;
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hide-loader {
  display: none !important;
}

.show-loader {
  display: block !important;
}

.closebtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.flexUpCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.flexUpCont button {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
}

.ratingCardsCont.modalOne {
  overflow-y: scroll;
  max-height: 80vh;
  padding-right: 1rem;
}

.ratingCardsCont.modalOne::-webkit-scrollbar {
  width: 10px;
}

.ratingCardsCont.modalOne::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.ratingCardsCont.modalOne::-webkit-scrollbar-track {
  background-color: #444444;
  border-radius: 5px;
}

.ratingsCont {
  padding: 20px;
}

.smolLogo {
  width: 50px !important;
}

.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(16 16 16 / 80%);
  z-index: 999999;
}

.react-hot-toast {
  z-index: 999999 !important;
  /* Ensure it stays above other modals */
}

.changedLayout {
  flex-direction: column;
  align-items: baseline;
  gap: 0;
}

.widthFifty input {
  color: #fff !important;
}

/* Overlay effect */
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Loader Box */
.loader-box {
  background-color: #2f3036;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Spinner */
.spinner-loader {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff1844;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

/* Logout text */
.loader-box p {
  margin-top: 20px;
  color: white;
  font-family: Kumbh Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
}

/* Keyframes for spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 500px) {
  .loader-box {
    width: 60%;
  }

  .spinner-loader {
    width: 30px;
    height: 30px;
  }

  .loader-box p {
    font-size: 12px;
  }
}
