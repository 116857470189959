.nav{
    width: 18%;
    margin: 3rem 0 1.5rem 0;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

@media screen and (max-width: 576px){
    .nav{
        width: 100%;
        margin: 0 auto 1.5rem auto;
        padding: 1.5rem 1rem;
        background: white;
        div{
            white-space: nowrap;
            font-size: 11px;
        }
    }
}