// .parties-checkbox {
//   display: none;
// }

// .parties-checkbox + label {
//   background-image: url("../../assets/heartunfilled.svg");
//   background-repeat: no-repeat;
//   height: 30px;
//   width: 30px;
//   border: 2px solid black;
// //   position: absolute;
// //   top: 10px;
// //   right: 15px;
//   display: inline-block;
// }

// .parties-checkbox:checked + label {
//   background-image: url("../../assets/heartfilled.svg");
//   background-repeat: no-repeat;
//   height: 30px;
//   width: 30px;
//   border: 2px solid black;
//   display: inline-block;
// }

// @media screen and (max-width: 411px) {
//   .parties-checkbox {
//     left: 155px;
//   }
// }

// @media screen and (max-width: 320px) {
//   .parties-checkbox {
//     left: 120px;
//   }
// }

// @media screen and (min-width: 1450px) {
//   .parties-checkbox {
//     left: 250px;
//   }
// }

:global(.favorite-icon){
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
}