.bank {
  min-width: 250px;
  max-width: 250px;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.addButton {
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.picked {
  background: #ff1844;
  color: white;
  border: none;
  &:hover {
    background: #ff1844;
    color: white;
    border: none;
  }
}
