.rcs_dotsSet {
	z-index: 1 !important;
	background-color: rgba(0, 0, 0, 0.5);
}

.ahe-style {
	padding: 2rem 0;
	margin-top: 1.5rem;
	margin-bottom: 3rem;
}

.cta-style {
	background: url('img/cta.png') rgba(0, 0, 0, 0.05) no-repeat center center;
	background-size: cover;
	height: 230px;
	width: 100%;
}

.btn-padding {
	padding: 0.5rem 1.5rem;
	border-radius: 10px;
}

.testimonial-slider {
	width: 75%;
	margin: 0 auto;
}

.carousel-indicators {
	bottom: -4rem;
	li {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #ff1844;
		border: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	display: none;
}

.skeleton {
	background: #ccc;
}

.skeleton-img {
	height: 150px;
	width: auto;
}
.skeleton-txt {
	height: 20px;
	width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
	color: #ff1844 !important;
	&:after {
		font-size: inherit;
	}
}

.line-height-footer {
	line-height: 1.5 !important;
}

.swiper-wrapper {
	align-items: center;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical
	> .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
	bottom: 0 !important;
	top: auto !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
	background: #ff1844;
}

.swiper-pagination-progressbar {
	background: rgba(0, 0, 0, 0.05);
}

.loader-booking-container {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.loader-booking {
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #ff1844;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
	position: absolute;
	left: 43%;
	top: 45%;
	transform: translate(-50%, -50%);
}

@media screen and (max-width: 576px) {
	.cta-style {
		background-size: cover;
		background-position: left top;
	}

	.testimonial-slider {
		width: 100%;
		margin: 0 auto;
	}
}

@media screen and (max-width: 850px) {

	.loader-booking,.float-right, h5, .h5 {
		font-size: 1rem !important;
	}

	.h5-css{
		font-size: 0.8rem !important;
	}

	.coupon-code{
		font-size: 0.6rem !important;
	}
}

@media screen and (min-width: 1400px) {
	.cta-style {
		background-size: cover;
	}
}
