.dropdown{
    position: absolute;
    z-index: 999;
    left: 65%;
}

.show{
    display: block;
}

.hide {
    display: none;
}

.dropdown-toggle::after {
    content: none;
}