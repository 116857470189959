.currency-input {
	padding: 6px 0.8rem 6px 0.8rem;
	margin-bottom: 0.8rem;
	background: #eaeaea;
	border-top: 1px solid rgb(133, 133, 133);
	border-left: 1px solid rgb(133, 133, 133);
	border-bottom: 1px solid rgb(133, 133, 133);

	@media screen and (max-width: 420px) {
		padding: 7px 0.8rem 7px 0.8rem;
	}
}

textarea[name='currentAddress'] {
	resize: none;
	padding: 0;
}

.select-location div p:first-of-type {
	color: #ff1844;
	font-weight: 900;
	font-size: 13px;
	margin: 0;
	cursor: pointer;
}

.select-location p:last-of-type {
	color: #ff1844;
	font-weight: 900;
	font-size: 10px;
	margin: 0;
	cursor: pointer;
}

.address {
	border: none;
	// font-size: 10px;
}

.address:focus {
	outline: none;
}

.submit {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.message {
	font-size: 11px;
	text-align: center;
}

.css-df17o1 {
	background: rgba(0, 0, 0, 0.8);
}

.react-datepicker-wrapper {
	width: 100% !important;
}
