.hostdetCont {
  border-top: 2px solid #3f3f464d;
  border-bottom: 2px solid #3f3f464d;
  padding: 2rem;
  margin: 2rem 0;
}

h3.partyDetTit {
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}

.hostNums p {
  margin-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;
  line-height: 17.36px;
  font-size: 14px;
  color: #c5c6cc;
}

.hostActDet h4 {
  line-height: 29.77px;
  font-size: 24px;
  font-family: 'Kumbh Sans', sans-serif;
}

.hostNums h2 {
  font-size: 40px;
  line-height: 50px;
}

.hostActDet h4 {
  margin-bottom: 0;
  text-transform: capitalize;
  cursor: pointer;
}

span.line {
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #fff;
  margin: 0px 5px;
}

.hostActDet {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hostProf img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  /* transition: all 0.25s linear; */
}

.hostProf img:hover {
  border: 3px solid #fff;
}

.flexyMid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partyInfo {
  border: 1px solid #88888880;
  background: #3337394d;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  position: relative;
  overflow: hidden;
}

.soldOutViewOver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  border: 1px solid #3f3f46;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soldOutViewOver img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.flexyInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.flexyInfo p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 22.32px;
  font-family: 'Kumbh Sans', sans-serif;
}

.flexyInfo .infoIcon {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: #3a86ff33;
  display: grid;
  place-items: center;
}

.flexyInfo .infoIcon.yellow {
  color: rgba(233, 211, 49, 0.2);
  background: rgba(233, 211, 49, 0.2);
}

.flexyInfo .infoIcon.yellow svg {
  stroke: rgba(233, 211, 49, 1);
}

.flexyInfo .infoIcon.two {
  color: #47c97e33;
  background: #47c97e33;
}

.flexyInfo .infoIcon.two svg {
  stroke: #47c97e;
}

.flexyInfo .infoIcon svg {
  stroke: #3a86ff;
}

.fakeExpandHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.partyHeading h1 {
  font-size: 36px;
  line-height: 39.6px;
  text-transform: uppercase;
}

.partyHeading .eventDetGap {
  font-size: 18px;
  line-height: 22.32px;
}

.partyHeading {
  margin-bottom: 2rem;
}

.expanderCont h3 {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
}

.kumBh {
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  line-height: 28.8px;
}

.hostNums {
  text-align: center;
}

.card-item {
  background-color: #2e2e2e;
  border-radius: 20px;
  list-style-type: none;
  color: white;
  border: 1px solid rgb(79, 77, 77);
  overflow: hidden;
  padding: 20px;
  font-family: 'Kumbh Sans', sans-serif;
}

.card-name {
  color: #171f46;
  font-size: 18px;
  font-weight: 500;
}

.card-description {
  color: #ffffff;

  font-size: 17px;
}

.img-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card-img {
  width: 55px;
  height: 57px;
  border-radius: 50%;
  border: 1px solid #fff;
  object-fit: cover;
}

.card-name {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
  text-transform: capitalize;
}

.btp {
  border-top: 1px solid rgba(63, 63, 70, 0.3019607843) !important;
}

.cards-app-container {
}

.cards-list-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
        align-items: center; */
  margin-top: 48px;
}

.heading {
  text-transform: uppercase;
  color: white;
  font-size: 24px;
}

.cards-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 0px;
  gap: 15px;
}

.header-rating-container {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  max-width: 720px;
}

.ratings-count {
  color: white;
  font-size: 24px;
  line-height: 33.6px;
  margin-bottom: 0;
  margin-right: 15px;
}

.see-all-button {
  margin-left: auto;
  padding: 10px 15px;
  background-color: transparent;
  color: white;
  border: 2px solid #3f3f46;
  border-radius: 9px;
  cursor: pointer;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 16px;
  line-height: 17.6px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.see-all-button:hover {
  background-color: #141714;
}

.rating-container {
  /* margin: 20px 0; */
  color: white;
  display: flex;
  gap: 5px;
}

.star {
  color: rgb(66, 64, 64);
}

.star.filled {
  color: rgb(250, 250, 249);
}

.header-image {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 16px;
  background-color: aliceblue;
}

.btn-arrow {
  font-size: 25px;
  padding: 0px 2px;
  position: relative;
  left: 8px;
  bottom: 1px;
}

.partyHeading .locationNdate p {
  max-width: 100%;
}

@media screen and (max-width: 576px) {
  .soldOutViewOver img {
    max-width: 130px;
  }

  .card-name {
    font-size: 13px;
  }

  .img-container {
    gap: 10px;
  }

  .card-img {
    width: 35px;
    height: 35px;
  }

  .card-description {
    font-size: 12px;
    line-height: 20px;
  }

  .heading {
    font-size: 18px;
  }

  .header-image {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  button.see-all-button svg {
    width: 20px;
  }

  .see-all-button {
    padding: 8px 10px;
    border-radius: 9px;
    font-size: 15px;
    line-height: 16.6px;
  }

  .ratings-count {
    font-size: 16px;
    line-height: 20.6px;

    margin-right: 10px;
  }

  span.star svg {
    width: 16px;
  }

  .hostProf img {
    width: 50px;
    height: 50px;
  }

  .hostActDet h4 {
    line-height: 20.77px;
    font-size: 14px;
  }

  .hostNums h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .hostNums p {
    line-height: 14.36px;
    font-size: 12px;
  }

  .flexyInfo p {
    font-size: 14px;
    line-height: 18.32px;
  }

  .flexyInfo .infoIcon {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }

  .flexyInfo .infoIcon svg {
    width: 16px;
  }

  .partyInfo {
    gap: 10px;
  }

  .partyHeading h1 {
    font-size: 18px;
    line-height: 20.6px;
  }

  .locationNdate p {
    margin-bottom: 0;
    font-size: 14px;
    max-width: 100%;
  }

  .flexyInfo {
    display: grid;
    gap: 10px;
    grid-template-columns: 30px 1fr;
  }

  h3.partyDetTit {
    font-size: 14px;
    line-height: 18px;
  }

  .hostdetCont {
    padding: 0.5rem;
    margin: 1rem 0;
  }
}
