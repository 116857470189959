small {
  // white-space: nowrap;
}

.text-approve {
  color: #6eb8bb;
}

.text-reject {
  color: #f45d7e;
}
