.container {
  display: flex;
}

.as-a-host {
  justify-self: flex-start;
  width: 50%;
  border-right: 2px solid #979797;
  padding: 1rem 2rem;
}

.as-a-guest {
  justify-self: flex-end;
  width: 50%;
  padding: 1rem 2rem;
}

.card-with-img {
  background: white;
  display: flex;
  border-radius: 15px;
//   border-right: 1rem solid #ff1844;
overflow-y: hidden;

  margin: 1.5rem 0;
  height: 140px;

  &__img {
    width: 25%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      min-width: 100%;
      height: 100%;
    }
  }

  &__content {
    border: none;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
}

.card-without-img {
  background: white;
  display: flex;
  border-radius: 15px;

  &__content {
    width: 100%;
  }
}

.loading-overlay {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.price) {
  white-space: nowrap;
}

:global(.border-pending) {
  border-right: 1rem solid #979797;
}

:global(.border-approved) {
  border-right: 1rem solid #ff1844;
}

.live-status {
	font-weight: bold;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		vertical-align: center;
		top: 1.2px;
		left: 42px;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		border: 2px solid #ff1844;
		background: #ff1844;
		animation: scale-up-center 0.4s ease-in-out infinite alternate both;
	}
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}


@media screen and (max-width: 576px) {
  .as-a-host {
    width: 100%;
    border: none;
  }

  .as-a-guest {
    width: 100%;
  }


  .card-with-img {
    // height: 140px;
    min-height: 140px;
    height: auto;
    margin: 1rem 0;
  }
}


@media screen and (max-width: 850px) {
  .card-with-img {

    // height: 140px;
    min-height: 140px;
    height: auto;
    &__img {
      width: 30%;
    }

  }

  .card-with-img h5, .h5{
    font-size: 0.8rem !important;
  }

}
